






























import { Component, Prop, Vue } from 'vue-property-decorator'
import { teamsStore } from '@/store'

@Component({
  name: 'SelectorInput',
  components: {
    IconTimes: () => import('@/components/UI/icons/IconTimes.vue'),
  },
})
export default class SelectorInput extends Vue {
  /**
   * Importance value (not level) to be displayed and updated by the component
   */
  @Prop({
    type: Number,
    default: null,
  }) readonly value!: number

  inputValue: string | null = null
  hasErrors = false
  errorMessage = ''

  get hint (): string {
    return teamsStore.getters.currentTeam.taskImportanceRev
      ? this.$t('tasks.importanceCaption.descending').toString()
      : this.$t('tasks.importanceCaption.ascending').toString()
  }

  get placeholder (): string {
    const key = 'common.withoutImportance'
    return this.$t(key/*, options */).toString()
  }

  created (): void {
    this.inputValue = this.value ? `${this.value}` : ''
  }

  onSubmit (): void {
    this.$emit('input', this.inputValue === '' ? null : Number(this.inputValue))
  }

  handleInput (input: string): void {
    this.inputValue = input
    const validationResult = this.validate(input)
    if (validationResult === true) {
      this.hasErrors = false
      this.$emit('input', input === '' ? null : Number(this.inputValue))
    } else {
      this.hasErrors = true
      this.errorMessage = validationResult
      this.$emit('error')
    }
  }

  validate (input: string): string | true {
    if (input === '') return true

    const typedInput = Number(input)
    if (typeof typedInput !== 'number') {
      return this.$t('common.validationErrors.number').toString()
    }
    if (!Number.isInteger(typedInput)) {
      return this.$t('common.validationErrors.integer').toString()
    }
    const team = teamsStore.getters.currentTeam
    const { taskImportanceMin: min, taskImportanceMax: max } = team
    if (typedInput < (min ?? 0) || typedInput > (max ?? 0)) {
      return this.$t('common.validationErrors.between', { from: min, to: max }).toString()
    }

    return true
  }
}
